
#jsv-holder {
    width: auto;
    top:auto;
    margin-top: 0%;
  }
  #jsv-holder img {
    width: 100%;  
    height: 100%;  
  }             

 
:root {
  --background: #FFFFFF;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #FFFFFF;
    --foreground: #171717;
  }
}



.page-section {
  padding: 150px 0;
}

.page-section h2.section-heading {
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 15px;
}


.page-section h3.section-subheading { 
  font-size: 16px;
  font-weight: 400;
  color: #FFA400 !important;
  font-style: italic;
  margin-bottom: 75px;
  text-transform: none;
  font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
}

.div-1 {
  background-color: #EBEBEB;
}

.div-2 {
  background-color: #ABBAEA;
}

.div-3 {
  background-color: #FBD603;
}



.sc-eCstlR 
{
max-height: 75px;
position: relative;
z-index: 200;
}

.sc-bdfBQB
{
  max-height: 230px;
  max-width: 100%;
  position: absolute;
  z-index: 300;
 overflow-y: scroll;
}
.row-banner
{
  background-color: #f7a209; 
  min-height: 200px;
  width: 100;
}

.card-text 
{
  color: black;
}
.card-header
{
  color: black;
}
.row-negro
{
 background-color: #242424;
}
.row-negro-inferior
{
 background-color: #242424;
 width: 100%;

}

section#contact .form-group input,
section#contact .form-group textarea {
  padding: 20px;

}

section#contact .form-group input.form-control {
  height: auto;
}

section#contact .form-group textarea.form-control {
  height: 248px;
}

.form-group
{
 
}

.icon 
{
  font-size: 15px;
  color: white;
  background-color:  transparent;
}

.imagen-activa
{
min-width: 100%;  
}

.row-banner2
{
  background-color:  #e5dfdf;
}

.col-gris {
  background-color: #e7e1e1;
  align-items: center;
  text-align: center;
}
.nav-item {
  color: black;
}


.search_input{
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  outline: none;
  font-size: 20px;
  transition: .2s ease-out;
  position: sticky;
  top: 5px;
  z-index: 123;
  text-align: center;
}

.custom-menu {
  width: 100%; /* Para abarcar todo el ancho disponible */
  max-width: 100%; /* Evita que el menú se salga de los límites */
  background-color: Black;
  margin-top: 50%;
  white-space:inherit; /* Evita que el texto se divida en múltiples líneas */
  flex-direction: column;
  z-index: 200; 
}



.custom-menu2 {
  width: auto; /* Para abarcar todo el ancho disponible */
  z-index: 100;
  text-align: center;
  flex-direction:row;
  display: grid;

 
 }
.menu-styling {
  z-index: 1; /* Asegúrate de que los menús tengan z-index adecuado */
  width: 90%; /* Asegúrate de que el menú use el 100% del contenedor */
}

.a 
{
  color: white;
}

.e-menu-url .e-menu .e-menu-item .e-menu-url .e-menu-container {
  color: white;
  display: grid;
}
.e-menu-wrapper 
{
  border: none;
    border-radius: 0;
    display: grid;
    line-height: 0;
}

.e-menu-title
{
  font-size: large; 
}

.e-menu-item{
  padding: 10x 10px; 
  display: grid; 
  z-index: 1;
  background-color: #e5dfdf;
  color: black;
  font-size: large;
  
}
  

.e-menu-header
{
  background-color: White;
}


.e-menu-parent
{
  color:  #171717;
  background-color: #171717;
}


/* BLOQUE PARA TIMELINE */

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}

.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: '';
  background-color: #e9ecef;
}

.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}

.timeline > li:after, .timeline > li:before {
  display: table;
  content: ' ';
}

.timeline > li:after {
  clear: both;
}

.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #e9ecef;
  border-radius: 100%;
  background-color: #FFA400;
}

.timeline > li .timeline-image h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4 {
  margin-top: 0;
  color: inherit;
}

.timeline .timeline-heading h4.subheading {
  text-transform: none;
}

.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}


/* FIN DE BLOQUE TIMELINE*/



/* bloque menu animado */
#mmeennuu {
  display: none;
}

#mmeennuu:checked ~ .menu {
  width: 180px;
  height: 270px;
  border-radius: 5px;
  background-color: #171717;
  border: 3px solid #171717;
  
  z-index: 200;
}
#mmeennuu:checked ~ .menu > ul {
  display:  inline;
  opacity: 1;
}
#mmeennuu:checked ~ .menu > .barry {
  display: none;
}

.menu {
  display: block;
  margin: 50px auto;
  width: 100px;
  height: 100px;
  background-color: #Black;
  border: 3px solid transparent;
  border-radius: 10%;
  overflow: hidden;
  cursor: pointer;
  position: absolute;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
}
.menu div.barry {
  width: 35px;
  margin: 35px auto;
}
.menu div.barry .bar {
  display: block;
  width: 100%;
  height: 5px;
  margin-top: 3px;
  border-radius: 2px;
  background-color: #171717;
}
.menu ul {
  opacity: 0;
  display: none;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  list-style-type: none;
  padding: 0;
  width: 100px;
  height: 1%;
  text-align: center;
  margin-bottom: 0;
}
.menu ul li {
  display: flow-root;
 
}
.menu ul li a {
 
  text-decoration: none;
  display: inline-block;
  padding: 2px 10px;
  color: white;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
  border-radius: 5px;
}
.menu ul li a:hover {
  border-color: transparent;
}
.menu ul li a:target {
  border-bottom-color: white;
}


/* fin bloque */


@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }
  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }
  .timeline > li .timeline-image h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}

@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }
  .timeline > li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }
  .timeline > li .timeline-image h4 {
    margin-top: 40px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

@media (max-width: 768px) {
 
  .menu-styling {
    width: 100%; /* Asegúrate de que el menú ocupe todo el ancho en móviles */
    max-width: 90%;
    position: relative; /* Cambiar a posición relativa en dispositivos móviles */
    
  }
  .row-banner2
{
  width: 100%;
  
  background-color:  242424;
}
  .custom-menu2 {
    width: 100%;
    margin: auto;     
    position: relative;
   
  }
  .row-banner
{
  background-color: #f7a209; 
  min-height: auto;
  
  width: 100%;
  
}
.e-menu {
  margin-top: 20px;
  width: 100%;
  display: grid;

}
.e-popup-open
{
  margin-top: 40px;
}

.e-menu-url .e-menu .e-menu-item .e-menu-url .e-menu-container {
  color: white;
}
.e-menu-parent
{
  max-width: 100%;
  position: relative;
}

.e-menu-item{
  padding: 10x 10px; 
 text-align: center;
  max-width: 100%;
  z-index: 1;
  background-color: #e5dfdf;
  color: black;
  font-size: medium;
}


.menu {
  display: block;
  margin: 10px auto;
  width: 80px;
  height: 80px;
  background-color: #Black;
  border: 3px solid transparent;
  border-radius: 10%;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
}


/* bloque timeline*/
.timeline:before {
  left: 50%;
}
.timeline > li {
  min-height: 100px;
  margin-bottom: 100px;
}
.timeline > li .timeline-panel {
  float: left;
  width: 41%;
  padding: 0 20px 20px 30px;
  text-align: right;
}
.timeline > li .timeline-image {
  left: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
}
.timeline > li .timeline-image h4 {
  font-size: 13px;
  line-height: 18px;
  margin-top: 16px;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 30px 20px 20px;
  text-align: left;
}



/* fin bloque timeline */


}